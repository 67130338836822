import { useState } from "react";
import {
  Button,
  Checkbox,
  DatePicker,
  Input,
  showToast,
  Tooltip,
} from "@attentive-platform/stem-ui";
import { useConvertToPro } from "../../Utils/services";
import { formatDate, isValidEmail } from "../../Utils/HelperFunctions";
import InfoIcon from "../../Assets/InfoIcon";

const DEFAULT_STATE = {
  email: "",
  planStartDate: null,
  nextBillingDate: null,
  requestConsumed: false,
  invitedUsers: false,
  chargeInitialProFee: false,
};

export const EnterpriseToPro = () => {
  const [formData, setFormData] = useState(DEFAULT_STATE);
  const { mutate, loading } = useConvertToPro();

  const onSubmitHandler = () => {
    const data = {
      email: formData.email,
      start_plan_date: formatDate(formData.planStartDate),
      next_billing_date: formatDate(formData.nextBillingDate),
      charge_request_consumed_on_next_billing_date: formData.requestConsumed,
      charge_invited_user_on_next_billing_date: formData.invitedUsers,
      charge_initial_pro_fee: formData.chargeInitialProFee,
    };
    mutate(
      { data },
      {
        onSuccess: () => {
          showToast("Conversion to Pro successful!");
          setFormData(DEFAULT_STATE);
        },
        onError: (error) => {
          showToast(error, "error");
        },
      }
    );
  };

  const onChangeHandler = (key, value) => {
    setFormData((prev) => ({
      ...prev,
      [key]: value,
    }));
  };

  const onCheckHandler = (event) => {
    const isChecked = event.target.checked;
    const value = event.target.value;

    setFormData((prev) => ({
      ...prev,
      [value]: isChecked,
    }));
  };

  const disabledCta =
    !formData.email ||
    !formData.planStartDate ||
    !formData.nextBillingDate ||
    !isValidEmail(formData.email);

  return (
    <div className='flex flex-col justify-center items-start gap-4 self-stretch w-full'>
      <div className='flex items-center gap-2'>
        <div className='flex text-base font-normal w-44'>Admin email</div>
        <Input
          size='small'
          value={formData.email}
          onChange={(e) => onChangeHandler("email", e.target.value)}
          name='cp-page-email'
          placeholder='Email of user'
          color='secondary'
        />
      </div>
      <div className='flex items-center gap-2'>
        <div className='flex text-base font-normal w-44'>Plan Start Date</div>
        <div>
          <DatePicker
            color='secondary'
            inputProps={{
              size: "small",
            }}
            value={formData.planStartDate}
            onChange={(date) => onChangeHandler("planStartDate", date)}
            maxDate={new Date()}
          />
        </div>
      </div>

      <div className='flex items-center gap-2'>
        <div className='flex text-base font-normal w-44'>Next Billing Date</div>
        <DatePicker
          value={formData.nextBillingDate}
          onChange={(date) => onChangeHandler("nextBillingDate", date)}
          placeholder='Next billing date'
          className='w-[176px] h-[32px]'
          inputProps={{
            size: "small",
            placeholder: "Next billing date",
          }}
          color='secondary'
        />
      </div>
      <div className='flex flex-col items-start  self-stretch'>
        <div className='flex text-base font-medium w-44'>
          Charge users for -
        </div>
        <div className='flex items-center gap-2'>
          <Checkbox
            value='requestConsumed'
            size='small'
            onChange={onCheckHandler}
            checked={formData.requestConsumed}
            className='w-2'
            color='secondary'
          />
          <span>Requests consumed on next billing date</span>
          <Tooltip
            arrow
            placement='top'
            title='The company will be charged on the next billing date for requests submitted by its members while it was classified as Enterprise.'
            variant='dark'
          >
            <div>
              <InfoIcon />
            </div>
          </Tooltip>{" "}
        </div>
        <div className='flex items-center gap-2'>
          <Checkbox
            value='invitedUsers'
            size='small'
            onChange={onCheckHandler}
            checked={formData.invitedUsers}
            className='w-2'
            color='secondary'
          />
          <span>Invited users on next billing date</span>
          <Tooltip
            arrow
            placement='top'
            title='The company will be charged on the next billing date for the users invited by the admin while it was classified as Enterprise.'
            variant='dark'
          >
            <div>
              <InfoIcon />
            </div>
          </Tooltip>
        </div>
        <div className='flex items-center gap-2'>
          <Checkbox
            value='chargeInitialProFee'
            size='small'
            onChange={onCheckHandler}
            checked={formData.chargeInitialProFee}
            className='w-2'
            color='secondary'
          />
          <span>Charge initial Pro fee</span>
          <Tooltip
            arrow
            placement='top'
            title='Users will be charged initial pro fee'
            variant='dark'
          >
            <div>
              <InfoIcon />
            </div>
          </Tooltip>
        </div>
      </div>
      <div className='flex justify-center items-center w-full'>
        <Button
          disabled={disabledCta}
          loading={loading}
          color='secondary'
          onClick={onSubmitHandler}
        >
          Convert to Pro
        </Button>
      </div>
    </div>
  );
};
