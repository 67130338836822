import {
  DataTable,
  SearchBar,
  Select,
  StyleProvider,
} from "@attentive-platform/stem-ui";
import React, { useEffect, useMemo, useState } from "react";
import Status from "./Columns/Status";
import { STATUS } from "../../Constants/Constants";
import { getPortfolioColumns } from "./Header";
import { fetchApiData } from "../../Utils/ApiCalls";
import { PORTFOLIO_ENDPOINT } from "../../urls";
import AddPortfolio from "./AddPortfolio";

const Portfolio = () => {
  const [page, setPage] = useState(0);
  const [searchText, setSearchText] = useState("");
  const [statusFilter, setStatusFilter] = useState();
  const [pageSize, setPageSize] = useState(10);
  const [portfolioData, setPortfolioData] = useState([]);
  const [isFetching, setIsFetching] = useState(false);
  const [showAddPortfolio, setShowAddPortfolio] = useState(false);

  const getPortFolioData = async () => {
    setIsFetching(true);
    const { data } = await fetchApiData({
      endpoint: PORTFOLIO_ENDPOINT,
      apiRequest: "GET",
      ...(searchText || statusFilter
        ? {
            params: {
              ...(statusFilter && { status: statusFilter }),
              ...(searchText && { portfolio_name: searchText }),
              page: page + 1,
              page_size: pageSize,
            },
          }
        : {}),
    });

    setIsFetching(false);
    setPortfolioData(data);
  };
  const columns = getPortfolioColumns(getPortFolioData, setPortfolioData);

  const handleSearchTextChange = (value) => {
    setPage(0);
    setSearchText(value);
  };

  const handleStatusChange = (status) => {
    setStatusFilter(status);
  };

  const handleChangePage = (page) => {
    setPage(page);
  };

  const handleChangePageSize = (pageSize) => {
    setPageSize(pageSize);
  };

  const paginationProps = useMemo(
    () => ({
      handleChangePage,
      handleChangePageSize,
      page,
      pageSize,
      hidePagination: !isFetching && (portfolioData || []).length === 0,
    }),
    [page, pageSize, isFetching, portfolioData]
  );

  useEffect(() => {
    getPortFolioData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchText, statusFilter, page, pageSize]);

  return (
    <div className="m-4">
      <div className="flex justify-between gap-2">
        <SearchBar
          color="secondary"
          placeholder="Search..."
          onChangeFn={(value) => handleSearchTextChange(value)}
          minChars={1}
        />
        <div className="flex w-56 items-center gap-2">
          <span className="text-md font-medium mr-2">Filters:</span>
          <Select
            allowClear
            color="primary"
            items={Object.entries(STATUS).map(([key, value]) => {
              return {
                label: value,
                value: Number(key),
              };
            })}
            renderOption={(...args) => {
              return <Status status={args[0].value} />;
            }}
            onChange={handleStatusChange}
            placeholder="Status"
            variant="std-filled"
            className="h-[2.8125rem]"
          />
        </div>
      </div>
      <div className="flex justify-between items-center pt-4">
        <StyleProvider>
          <DataTable
            rowProps={{ isSelectable: () => false }}
            allowBulkSelect={false}
            isLoading={isFetching}
            data={portfolioData?.results || []}
            columns={columns}
            options={{
              showFooter: false,
              stickyHeader: true,
            }}
            classes={{
              "csm-container": "scrollbar",
            }}
            createRowProps={{
              showCreateRow: true,
              createRowBtnText: "Add Portfolio",
              handleClickCreateBtn: () => setShowAddPortfolio(true),
            }}
            bodyScrollProps={{ bodyScrollHeight: "36rem" }}
            paginationProps={paginationProps}
            totalCountProps={{
              showTotalCount: true,
              totalRowCount: portfolioData?.count,
            }}
          />
        </StyleProvider>
      </div>
      <AddPortfolio
        show={showAddPortfolio}
        setShow={setShowAddPortfolio}
        getPortFolioData={getPortFolioData}
      />
    </div>
  );
};
export default Portfolio;
