import * as XLSX from "xlsx";
import { useEffect, useState } from "react";
import {
  Autocomplete,
  Button,
  Checkbox,
  DateRangePicker,
  Divider,
  FileUploadButton,
  Input,
  Modal,
  StyleProvider,
  showToast,
} from "@attentive-platform/stem-ui";

import InputField from "../InputField";
import UploadIcon from "../../Assets/UploadIcon";
import {
  COUPONS_ENDPOINT,
  PROMOTIONAL_EDIT,
  PROMOTIONAL_ENDPOINT,
} from "../../urls";
import { fetchApiData, interpolate } from "../../Utils/ApiCalls";
import { getPromotionPayload } from "./PromotionUtils";

const PromotionalModal = ({
  onClose,
  isEditing,
  showModal,
  promotionFormData,
  setPromotionalData,
  setPromotionFormData,
  selectedCoupon,
  setSelectedCoupon,
}) => {
  const requiredFields = ["name", "start_date", "end_date", "coupon"];
  const [error, setError] = useState({});
  const [loading, setLoading] = useState(false);
  const [coupons, setCoupons] = useState([]);

  useEffect(() => {
    getCoupons();
  }, []);

  const getCoupons = async () => {
    const { data } = await fetchApiData({
      endpoint: COUPONS_ENDPOINT,
      apiRequest: "GET",
      params: {
        page: 1,
        page_size: 100,
      },
    });
    const couponData = data?.results.map((c) => {
      return { label: c.code, value: c.id };
    });
    setCoupons(couponData);
  };

  const handleChange = (key, value) => {
    if (key === "start_date") {
      setPromotionFormData({
        ...promotionFormData,
        start_date: value.start,
        end_date: value.end,
      });
    } else {
      setPromotionFormData({
        ...promotionFormData,
        [key]: value,
      });
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    promotionFormData.coupon = selectedCoupon?.value;
    delete promotionFormData.coupon_id;
    const errors = validateFields(promotionFormData);
    setError(errors);
    if (Object.keys(errors).length > 0) {
      return;
    }

    const payload = getPromotionPayload(promotionFormData);

    setLoading(true);
    const { data, error } = await fetchApiData({
      endpoint: isEditing
        ? interpolate(PROMOTIONAL_EDIT, [promotionFormData.id])
        : PROMOTIONAL_ENDPOINT,
      apiRequest: isEditing ? "PATCH" : "POST",
      data: payload,
    });

    if (isEditing) {
      setPromotionalData((prev) =>
        prev.map((item) => (item.id === promotionFormData.id ? data : item))
      );
    } else {
      setPromotionalData((prev) => [data, ...prev]);
    }

    setLoading(false);
    if (error) {
      showToast(error, "error");
      return;
    }

    showToast(
      isEditing
        ? "Promotion updated successfully"
        : "Promotion added successfully",
      "success"
    );
    onClose();
    setError({});
  };

  const validateFields = (fields) => {
    const errors = {};
    requiredFields.forEach((field) => {
      if (!fields[field] || fields[field].length === 0) {
        errors[field] = `${field.replace(/_/g, " ")} is required`;
        if (field === "coupon") {
          showToast(`Coupon is required`, "error");
        }
      }
    });
    return errors;
  };

  const onCheckHandler = (event) => {
    const isChecked = event.target.checked;
    const value = event.target.value;

    setPromotionFormData((prev) => ({
      ...prev,
      [value]: isChecked,
    }));
  };

  const handleFile = (file) => {
    const reader = new FileReader();

    reader.onload = (e) => {
      const data = new Uint8Array(e.target.result);
      const workbook = XLSX.read(data, { type: "array" });
      const sheetName = workbook.SheetNames[0];
      const sheet = workbook.Sheets[sheetName];

      const json = XLSX.utils.sheet_to_json(sheet, { header: 1, defval: "" });

      const values = json.flat().filter((val) => val);

      if (values.length > 0) {
        setPromotionFormData((prev) => ({
          ...prev,
          issued_for: values.join(","),
        }));
      } else {
        showToast("No data found in the file!", "error");
      }
    };

    reader.readAsArrayBuffer(file);
  };

  return (
    <Modal
      show={showModal}
      heading={
        <span className="pl-4">
          {isEditing
            ? "Edit Promotional Program"
            : "Create Promotional Program"}
        </span>
      }
      onClose={onClose}
      content={
        <StyleProvider theme="attentive-internal">
          <div className="flex flex-col gap-2 m-4 w-[500px]">
            <InputField
              label="Program Name"
              keyName="name"
              type="text"
              placeholder="Enter Name"
              value={promotionFormData.name}
              onChange={handleChange}
              error={error?.name}
              extraProps={{
                className: "w-full",
              }}
            />

            <span className="text-sm font-medium">Date Range:</span>
            <DateRangePicker
              color="primary"
              value={{
                start: promotionFormData.start_date,
                end: promotionFormData.end_date,
              }}
              onChange={(value) => handleChange("start_date", value)}
            />

            <span className="text-sm font-medium mt-2">Coupon Code:</span>
            <Autocomplete
              creatable={false}
              color="primary"
              size="small"
              options={coupons}
              value={selectedCoupon || ""}
              onChange={(_event, newValue) => {
                if (!newValue) {
                  setSelectedCoupon("");
                  return;
                }
                setSelectedCoupon(newValue);
              }}
              renderInput={({ ...props }) => (
                <Input
                  {...props}
                  color="primary"
                  placeholder="Search and select coupon code"
                />
              )}
              style={{ width: "100%" }}
            />

            <div className="flex gap-2 items-center mt-2">
              <Checkbox
                value="only_for_new_subscriptions"
                size="small"
                onChange={onCheckHandler}
                checked={promotionFormData.only_for_new_subscriptions}
                className="w-2 h-6"
              />
              <span>Applicable on platform fee for new subscriptions</span>
            </div>

            <div className="flex gap-2 items-center">
              <Checkbox
                value="applicable_for_platform_fee"
                size="small"
                onChange={onCheckHandler}
                checked={promotionFormData.applicable_for_platform_fee}
                className="w-2 h-6"
              />
              <span>Applicable on platform fee for renewal</span>
            </div>

            <div className="flex gap-2 items-center mb-2">
              <Checkbox
                value="applicable_for_takeoffs"
                size="small"
                onChange={onCheckHandler}
                checked={promotionFormData.applicable_for_takeoffs}
                className="w-2 h-6"
              />
              <span>Applicable on takeoffs</span>
            </div>

            <InputField
              label="Applicable for the following companies"
              keyName="issued_for"
              type="text"
              placeholder="Enter company id seperated by commas without any single or double quotes"
              value={promotionFormData.issued_for}
              onChange={handleChange}
              error={error?.issued_for}
              extraProps={{
                minRows: 3,
                multiline: true,
                className: "w-full",
              }}
            />

            <div className="flex gap-2 items-center">OR</div>

            <div className="flex gap-2 items-center">
              <FileUploadButton
                handleFile={handleFile}
                accept=".xlsx"
                renderButton={renderButton}
              />
              <span className="text-sm font-medium cursor-pointer underline">
                <a
                  href={
                    "https://docs.google.com/spreadsheets/d/1we7HGN4TxpY4n9L7TWpWQJBap6g7F-b1B1jTeboBj4w/"
                  }
                  target="_blank"
                  rel="noreferrer"
                >
                  Click here to view sample file
                </a>
              </span>
            </div>

            <Divider />

            <div className="flex justify-end gap-2">
              <Button size="medium" variant="outlined" onClick={onClose}>
                Cancel
              </Button>
              <Button
                loading={loading}
                size="medium"
                color="primary"
                onClick={handleSubmit}
              >
                {isEditing ? "Update" : "Create"}
              </Button>
            </div>
          </div>
        </StyleProvider>
      }
    />
  );
};

export default PromotionalModal;

const renderButton = (props) => (
  <Button size="small" variant="outlined" startIcon={<UploadIcon />} {...props}>
    Upload Excel File
  </Button>
);
