import { Switch } from "@attentive-platform/stem-ui";
import { format, parseISO } from "date-fns";
import EditIcon from "../../Assets/EditIcon";
import { StyledBreakWordContainer } from "../../styled";

export const getPromotionalColumnData = (onEditPromotion, changeActive) => {
  const columns = [
    {
      Header: "Program Name",
      accessor: "name",
      isSticky: true,
      Cell: ({ row }) => {
        return (
          <div className='flex w-40'>
            <StyledBreakWordContainer>
              {row?.values?.name ? row.values.name : "-"}
            </StyledBreakWordContainer>
          </div>
        );
      },
    },
    {
      Header: "Coupon",
      accessor: "coupon",
      Cell: ({ row }) => {
        return row?.values?.coupon ? (
          <div className='flex justify-center w-40'>{row.values.coupon}</div>
        ) : (
          "-"
        );
      },
      align: "center",
      allowColumnConfig: true,
    },
    {
      Header: "Created At",
      accessor: "created_at",
      Cell: ({ row }) => {
        return (
          <div className='flex justify-center w-40'>
            {format(parseISO(row.values.created_at), "yyyy-MM-dd")}
          </div>
        );
      },
      align: "center",
      allowColumnConfig: true,
    },
    {
      Header: "Start Date",
      accessor: "start_date",
      Cell: ({ row }) => {
        return (
          <>
            <div className='flex justify-center w-40'>
              {row.values.start_date ? row.values.start_date : "-"}
            </div>
          </>
        );
      },
      align: "center",
      allowColumnConfig: true,
    },
    {
      Header: "End Date",
      accessor: "end_date",
      Cell: ({ row }) => {
        return (
          <>
            <div className='flex justify-center w-40'>
              {row.values.end_date ? row.values.end_date : "-"}
            </div>
          </>
        );
      },
      align: "center",
      allowColumnConfig: true,
    },
    {
      Header: "Is Active",
      accessor: "is_active",
      Cell: ({ row }) => {
        return (
          <Switch
            checked={row.values.is_active}
            onChange={(e) => changeActive(e, row)}
          />
        );
      },
      align: "center",
    },
    {
      Header: "Actions",
      accessor: "actions",
      Cell: ({ row }) => {
        return (
          <div onClick={() => onEditPromotion(row.original)}>
            <EditIcon />
          </div>
        );
      },
      isSticky: true,
      align: "center",
    },
  ];

  return columns;
};
