import { format } from "date-fns";

export const getPromotionPayload = (
  promotionFormData,
  changeDateFormat = true
) => {
  const payload = new FormData();
  Object.keys(promotionFormData).forEach((key) => {
    if (changeDateFormat && (key === "start_date" || key === "end_date")) {
      payload.append(
        key,
        format(new Date(promotionFormData[key]), "yyyy-MM-dd")
      );
    } else if (key === "issued_for") {
      promotionFormData[key]
        .split(",")
        .map((s) => s.trim())
        .forEach((value) => {
          payload.append(key, value);
        });
    } else {
      payload.append(key, promotionFormData[key]);
    }
  });

  return payload;
};
