import React from "react";

const InfoIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="21"
      height="20"
      viewBox="0 0 21 20"
      fill="none"
    >
      <path
        d="M9.66675 14.1667H11.3334V9.16669H9.66675V14.1667ZM10.5001 7.50002C10.7362 7.50002 10.9341 7.42016 11.0938 7.26044C11.2536 7.10072 11.3334 6.9028 11.3334 6.66669C11.3334 6.43058 11.2536 6.23266 11.0938 6.07294C10.9341 5.91322 10.7362 5.83335 10.5001 5.83335C10.264 5.83335 10.0661 5.91322 9.90633 6.07294C9.74661 6.23266 9.66675 6.43058 9.66675 6.66669C9.66675 6.9028 9.74661 7.10072 9.90633 7.26044C10.0661 7.42016 10.264 7.50002 10.5001 7.50002ZM10.5001 18.3334C9.3473 18.3334 8.26397 18.1146 7.25008 17.6771C6.23619 17.2396 5.35425 16.6459 4.60425 15.8959C3.85425 15.1459 3.2605 14.2639 2.823 13.25C2.3855 12.2361 2.16675 11.1528 2.16675 10C2.16675 8.84724 2.3855 7.76391 2.823 6.75002C3.2605 5.73613 3.85425 4.85419 4.60425 4.10419C5.35425 3.35419 6.23619 2.76044 7.25008 2.32294C8.26397 1.88544 9.3473 1.66669 10.5001 1.66669C11.6529 1.66669 12.7362 1.88544 13.7501 2.32294C14.764 2.76044 15.6459 3.35419 16.3959 4.10419C17.1459 4.85419 17.7397 5.73613 18.1772 6.75002C18.6147 7.76391 18.8334 8.84724 18.8334 10C18.8334 11.1528 18.6147 12.2361 18.1772 13.25C17.7397 14.2639 17.1459 15.1459 16.3959 15.8959C15.6459 16.6459 14.764 17.2396 13.7501 17.6771C12.7362 18.1146 11.6529 18.3334 10.5001 18.3334ZM10.5001 16.6667C12.3612 16.6667 13.9376 16.0209 15.2292 14.7292C16.5209 13.4375 17.1667 11.8611 17.1667 10C17.1667 8.13891 16.5209 6.56252 15.2292 5.27085C13.9376 3.97919 12.3612 3.33335 10.5001 3.33335C8.63897 3.33335 7.06258 3.97919 5.77092 5.27085C4.47925 6.56252 3.83341 8.13891 3.83341 10C3.83341 11.8611 4.47925 13.4375 5.77092 14.7292C7.06258 16.0209 8.63897 16.6667 10.5001 16.6667Z"
        fill="#4D4D4D"
      />
    </svg>
  );
};

export default InfoIcon;
