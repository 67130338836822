import { format, parseISO } from "date-fns";
import { DISCOUNT_TYPE } from "../../Constants/Constants";
import EditIcon from "../../Assets/EditIcon";

export const getCouponColumnData = (onEditCoupon) => {
  const columns = [
    {
      Header: "Coupon Code",
      accessor: "code",
      align: "center",
      isSticky: true,
      Cell: ({ row }) => {
        return (
          <div className="flex w-40 justify-center">
            {row?.values?.code ? row.values.code : "-"}
          </div>
        );
      },
    },
    {
      Header: "Coupon Value",
      accessor: "qty",
      Cell: ({ row }) => {
        return row?.values?.qty ? (
          <div className="flex justify-center w-40">{row.values.qty}</div>
        ) : (
          "-"
        );
      },
      align: "center",
      allowColumnConfig: true,
    },
    {
      Header: "Discount Type",
      accessor: "unit",
      align: "center",
      Cell: ({ row }) => {
        return (
          <div className="flex justify-center w-40">
            {DISCOUNT_TYPE[row.values.unit]}
          </div>
        );
      },
      allowColumnConfig: true,
    },
    {
      Header: "Created At",
      accessor: "created_at",
      Cell: ({ row }) => {
        return (
          <div className="flex justify-center w-40">
            {format(parseISO(row.values.created_at), "yyyy-MM-dd")}
          </div>
        );
      },
      align: "center",
      allowColumnConfig: true,
    },
    {
      Header: "Created By",
      accessor: "issuer_name",
      Cell: ({ row }) => {
        return (
          <div className="flex justify-center w-40">
            {row.values.issuer_name ? row.values.issuer_name : "-"}
          </div>
        );
      },
      align: "center",
      allowColumnConfig: true,
    },
    {
      Header: "Action",
      accessor: "action",
      Cell: ({ row }) => {
        return (
          <div onClick={() => onEditCoupon(row.original)}>
            <EditIcon />
          </div>
        );
      },
      isSticky: true,
      align: "center",
    },
  ];

  return columns;
};
