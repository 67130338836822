import { memo, useMemo, useState } from "react";
import {
  Button,
  Divider,
  Modal,
  Select,
  StyleProvider,
  showToast,
} from "@attentive-platform/stem-ui";
import { COUPON_EDIT, COUPONS_ENDPOINT } from "../../urls";
import { fetchApiData, interpolate } from "../../Utils/ApiCalls";
import {
  CURRENCY_DROPDOWN,
  DISCOUNT_TYPE_DROPDOWN,
  DISCOUNT_TYPE_ENUM,
} from "../../Constants/Constants";
import InputField from "../InputField";

const CouponModal = memo(
  ({
    showModal,
    onClose,
    setCouponData,
    couponFormData,
    setCouponFormData,
    isEditing,
  }) => {
    const [error, setError] = useState({});
    const [loading, setLoading] = useState(false);

    const requiredFields = useMemo(() => {
      return couponFormData.unit === DISCOUNT_TYPE_ENUM.PERCENTAGE
        ? ["code", "qty", "max_discount_value"]
        : ["code", "qty"];
    }, [couponFormData.unit]);

    const handleChange = (key, value) => {
      setCouponFormData({
        ...couponFormData,
        [key]: value,
      });
    };

    const handleSubmit = async (e) => {
      e.preventDefault();

      const errors = validateFields(couponFormData);
      setError(errors);
      if (Object.keys(errors).length > 0) {
        return;
      }

      const payload = new FormData();
      Object.keys(couponFormData).forEach((key) => {
        payload.append(key, couponFormData[key]);
      });

      setLoading(true);
      const { data, error } = await fetchApiData({
        endpoint: isEditing
          ? interpolate(COUPON_EDIT, [couponFormData.id])
          : COUPONS_ENDPOINT,
        apiRequest: isEditing ? "PATCH" : "POST",
        data: payload,
      });
      if (isEditing) {
        setCouponData((prev) =>
          prev.map((item) => (item.id === couponFormData.id ? data : item))
        );
      } else {
        setCouponData((prev) => [data, ...prev]);
      }

      setLoading(false);

      if (error) {
        showToast(error, "error");
        return;
      }

      showToast("Coupon added successfully", "success");
      onClose();
      setError({});
    };

    const validateFields = (fields) => {
      const errors = {};
      requiredFields.forEach((field) => {
        if (!fields[field] || fields[field].length === 0) {
          errors[field] = `${field.replace(/_/g, " ")} is required`;
        }
      });
      return errors;
    };

    return (
      <Modal
        show={showModal}
        heading={
          <span className="pl-4">
            {isEditing ? "Edit Coupon" : "Create New Coupon"}
          </span>
        }
        onClose={onClose}
        content={
          <StyleProvider theme="attentive-internal">
            <div className="flex flex-col gap-2 m-4">
              <InputField
                label="Coupon Code"
                keyName="code"
                type="text"
                placeholder="Enter Coupon Code"
                value={couponFormData.code}
                onChange={handleChange}
                error={error?.code}
              />

              <span className="text-sm font-medium">Discount Type:</span>
              <Select
                items={DISCOUNT_TYPE_DROPDOWN}
                onChange={(value) => {
                  handleChange("unit", value);
                }}
                placeholder="Select Discount Type"
                label="Discount Type"
                value={couponFormData.unit}
                className="mb w-72"
              />

              <InputField
                label="Discount Value"
                keyName="qty"
                type="text"
                placeholder="Enter Discount Value"
                value={couponFormData.qty}
                onChange={handleChange}
                error={error?.qty}
              />
              {couponFormData.unit === DISCOUNT_TYPE_ENUM.PERCENTAGE && (
                <InputField
                  label="Max Discount"
                  keyName="max_discount_value"
                  type="text"
                  placeholder="Enter Max Discount"
                  value={couponFormData.max_discount_value}
                  onChange={handleChange}
                  error={error?.max_discount_value}
                  extraProps={{
                    dropDownItems: CURRENCY_DROPDOWN,
                    withDropDown: true,
                    dropDownHeight: "30px",
                    dropDownValue: couponFormData.currency || 1,
                    onDropDownChange: (e) => {
                      handleChange("currency", e.target.value);
                    },
                  }}
                />
              )}

              <Divider />

              <div className="flex justify-center gap-2 mt-2">
                <Button size="medium" variant="outlined" onClick={onClose}>
                  Cancel
                </Button>
                <Button
                  loading={loading}
                  size="medium"
                  color="primary"
                  onClick={handleSubmit}
                >
                  {isEditing ? "Update" : "Create"}
                </Button>
              </div>
            </div>
          </StyleProvider>
        }
      />
    );
  }
);

export default CouponModal;
