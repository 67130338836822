import { Input } from "@attentive-platform/stem-ui";

const InputField = ({
  label,
  keyName,
  type,
  placeholder,
  value,
  onChange,
  error,
  extraProps,
}) => (
  <div className="flex flex-col mb">
    <span className="text-sm font-medium mb">{label}:</span>
    <Input
      placeholder={placeholder}
      size="small"
      type={type}
      className="w-72"
      value={value}
      onChange={(e) =>
        onChange(
          keyName,
          type === "number" ? Number(e.target.value) : e.target.value
        )
      }
      {...extraProps}
    />
    {error && <span className="text-red-500 text-xs">{error}</span>}
  </div>
);
export default InputField;
