import { useEffect, useMemo, useState } from "react";
import { RECORDS_PER_PAGE } from "../Constants/Constants";

const usePagination = ({ count }) => {
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [recordsPerPage, setRecordsPerPage] = useState(RECORDS_PER_PAGE);

  useEffect(() => {
    setTotalPages(Math.max(1, Math.ceil(count / recordsPerPage)));
  }, [count, recordsPerPage]);

  const pageNumbers = useMemo(() => {
    // Number of pages to show
    const pagesToShow = 10;
    // Initial and maximum page numbers
    const initPage = Math.floor((page - 1) / pagesToShow) * pagesToShow + 1;
    const maxPage = Math.min(initPage + pagesToShow, totalPages + 1);
    // set page numbers as a static array
    return [
      ...Array.from({ length: maxPage - initPage }, (_, i) => initPage + i),
    ];
  }, [page, totalPages]);

  return {
    page,
    setPage,
    totalPages,
    pageNumbers,
    recordsPerPage,
    setRecordsPerPage,
  };
};

export default usePagination;
