import React, { useCallback, useEffect, useMemo, useState } from "react";
import {
  DataTable,
  SearchBar,
  showToast,
  StyleProvider,
} from "@attentive-platform/stem-ui";

import { PROMOTIONAL_EDIT, PROMOTIONAL_ENDPOINT } from "../../urls";
import { fetchApiData, interpolate } from "../../Utils/ApiCalls";
import { getPromotionalColumnData } from "./PromotionalColData";
import { EMPTY_ARRAY } from "../../Constants/Constants";
import { getPromotionPayload } from "./PromotionUtils";
import Pagination from "../Pagination";
import PromotionalModal from "./PromotionalModal";
import usePagination from "../../Hooks/usePagination";

const DEFAULT_PROMOTION_STATE = {
  name: "",
  start_date: "",
  end_date: "",
  coupon: "",
  coupon_id: "",
  only_for_new_subscriptions: false,
  applicable_for_platform_fee: false,
  applicable_for_takeoffs: false,
  issued_for: "",
};

const Promotional = () => {
  const [count, setCount] = useState(0);
  const [searchText, setSearchText] = useState("");
  const [isEditing, setIsEditing] = useState(false);
  const [isFetching, setIsFetching] = useState(false);
  const [promotionalData, setPromotionalData] = useState([]);
  const [promotionFormData, setPromotionFormData] = useState(
    DEFAULT_PROMOTION_STATE
  );
  const [showPromotionalModal, setShowPromotionalModal] = useState(false);
  const [selectedCoupon, setSelectedCoupon] = useState("");

  const {
    page,
    setPage,
    totalPages,
    pageNumbers,
    recordsPerPage,
    setRecordsPerPage,
  } = usePagination({ count });

  const getPromotionalData = useCallback(async () => {
    setIsFetching(true);
    const { data } = await fetchApiData({
      endpoint: PROMOTIONAL_ENDPOINT,
      apiRequest: "GET",
      params: {
        ...(searchText && { name: searchText }),
        page: page,
        page_size: recordsPerPage,
      },
    });

    setIsFetching(false);
    setPromotionalData(data?.results || EMPTY_ARRAY);
    setCount(data?.count || 0);
  }, [searchText, page, recordsPerPage]);

  useEffect(() => {
    getPromotionalData();
  }, [searchText, page, recordsPerPage, getPromotionalData]);

  const onEditPromotion = (promotion) => {
    setIsEditing(true);
    setPromotionFormData({
      ...promotion,
      start_date: new Date(promotion.start_date + "T00:00:00"),
      end_date: new Date(promotion.end_date + "T00:00:00"),
      issued_for: promotion.issued_for.join(","),
    });
    const coupon =
      promotion?.coupon_id && promotion?.coupon
        ? {
            label: promotion?.coupon,
            value: promotion?.coupon_id,
          }
        : "";
    setSelectedCoupon(coupon);
    setShowPromotionalModal(true);
  };

  const changeActive = async (e, row) => {
    const promotionFormData = row.original;
    promotionFormData.is_active = e.target.checked;
    promotionFormData.issued_for = promotionFormData.issued_for.join(",");
    promotionFormData.coupon = promotionFormData.coupon_id;

    const payload = getPromotionPayload(promotionFormData, false);
    const { data, error } = await fetchApiData({
      endpoint: interpolate(PROMOTIONAL_EDIT, [promotionFormData.id]),
      apiRequest: "PATCH",
      data: payload,
    });
    setPromotionalData((prev) =>
      prev.map((item) => (item.id === promotionFormData.id ? data : item))
    );
    if (error) {
      showToast(error, "error");
      return;
    } else {
      showToast("Promotion updated successfully", "success");
    }
  };

  const columns = useMemo(
    () => getPromotionalColumnData(onEditPromotion, changeActive),
    []
  );

  const handleSearchTextChange = (value) => {
    setPage(1);
    setSearchText(value);
  };

  const onClose = () => {
    setShowPromotionalModal(false);
    setPromotionFormData((prev) => ({
      ...prev,
      ...DEFAULT_PROMOTION_STATE,
    }));
    setIsEditing(false);
    setSelectedCoupon("");
  };

  return (
    <div className="m-4">
      <div className="flex justify-between gap-2">
        <SearchBar
          color="secondary"
          placeholder="Search..."
          onChangeFn={(value) => handleSearchTextChange(value)}
          minChars={1}
        />
      </div>

      <div className="pt-4">
        <StyleProvider>
          <DataTable
            rowProps={{ isSelectable: () => false }}
            allowBulkSelect={false}
            isLoading={isFetching}
            data={promotionalData}
            columns={columns}
            options={{
              showFooter: false,
              stickyHeader: true,
            }}
            classes={{
              container: "scrollbar",
            }}
            createRowProps={{
              showCreateRow: true,
              createRowBtnText: "Create Promotional Program",
              handleClickCreateBtn: () => setShowPromotionalModal(true),
            }}
            paginationProps={{ hidePagination: true }}
          />
          <div className="mt-4">
            <Pagination
              totalPages={totalPages}
              setPage={setPage}
              page={page}
              pageNumbers={pageNumbers}
              setRecordsPerPage={setRecordsPerPage}
              recordsPerPage={recordsPerPage}
            />
          </div>
        </StyleProvider>
      </div>

      {showPromotionalModal && (
        <PromotionalModal
          onClose={onClose}
          isEditing={isEditing}
          showModal={showPromotionalModal}
          promotionFormData={promotionFormData}
          setPromotionalData={setPromotionalData}
          setPromotionFormData={setPromotionFormData}
          selectedCoupon={selectedCoupon}
          setSelectedCoupon={setSelectedCoupon}
        />
      )}
    </div>
  );
};

export default Promotional;
