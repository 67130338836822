import React, { useCallback, useEffect, useMemo, useState } from "react";
import {
  DataTable,
  SearchBar,
  StyleProvider,
} from "@attentive-platform/stem-ui";

import { COUPONS_ENDPOINT } from "../../urls";
import { fetchApiData } from "../../Utils/ApiCalls";
import { getCouponColumnData } from "./CouponColumnData";
import { EMPTY_ARRAY } from "../../Constants/Constants";
import Pagination from "../Pagination";
import AddCouponModal from "./CouponModal";
import usePagination from "../../Hooks/usePagination";

const DEFAULT_COUPON_STATE = {
  code: "",
  unit: 2,
  qty: "",
  max_discount_value: "",
  currency: "",
};

const Coupon = () => {
  const [count, setCount] = useState(0);
  const [searchText, setSearchText] = useState("");
  const [isEditing, setIsEditing] = useState(false);
  const [couponsData, setCouponsData] = useState([]);
  const [isFetching, setIsFetching] = useState(false);
  const [couponFormData, setCouponFormData] = useState(DEFAULT_COUPON_STATE);
  const [showCouponModal, setShowCouponModal] = useState(false);

  const {
    page,
    setPage,
    totalPages,
    pageNumbers,
    recordsPerPage,
    setRecordsPerPage,
  } = usePagination({ count });

  const getCouponData = useCallback(async () => {
    setIsFetching(true);
    const { data } = await fetchApiData({
      endpoint: COUPONS_ENDPOINT,
      apiRequest: "GET",
      params: {
        ...(searchText && { code: searchText }),
        page: page,
        page_size: recordsPerPage,
      },
    });

    setIsFetching(false);
    setCouponsData(data?.results || EMPTY_ARRAY);
    setCount(data?.count || 0);
  }, [searchText, page, recordsPerPage]);

  useEffect(() => {
    getCouponData();
  }, [searchText, page, recordsPerPage, getCouponData]);

  const onEditCoupon = (coupon) => {
    setIsEditing(true);
    setCouponFormData(coupon);
    setShowCouponModal(true);
  };

  const columns = useMemo(() => {
    return getCouponColumnData(onEditCoupon);
  }, []);

  const handleSearchTextChange = (value) => {
    setPage(1);
    setSearchText(value);
  };

  const onClose = () => {
    setShowCouponModal(false);
    setCouponFormData((prev) => ({
      ...prev,
      ...DEFAULT_COUPON_STATE,
    }));
    setIsEditing(false);
  };

  return (
    <div className="m-4">
      <div className="flex justify-between gap-2">
        <SearchBar
          color="secondary"
          placeholder="Search..."
          onChangeFn={(value) => handleSearchTextChange(value)}
          minChars={1}
        />
      </div>
      <div className="pt-4">
        <StyleProvider>
          <DataTable
            rowProps={{ isSelectable: () => false }}
            allowBulkSelect={false}
            isLoading={isFetching}
            data={couponsData}
            columns={columns}
            options={{
              showFooter: false,
              stickyHeader: true,
            }}
            classes={{
              container: "scrollbar",
            }}
            createRowProps={{
              showCreateRow: true,
              createRowBtnText: "Add Coupon",
              handleClickCreateBtn: () => setShowCouponModal(true),
            }}
            paginationProps={{ hidePagination: true }}
          />
          <div className="mt-4">
            <Pagination
              totalPages={totalPages}
              setPage={setPage}
              page={page}
              pageNumbers={pageNumbers}
              setRecordsPerPage={setRecordsPerPage}
              recordsPerPage={recordsPerPage}
            />
          </div>
        </StyleProvider>
      </div>
      {showCouponModal && (
        <AddCouponModal
          onClose={onClose}
          isEditing={isEditing}
          showModal={showCouponModal}
          setCouponData={setCouponsData}
          couponFormData={couponFormData}
          setCouponFormData={setCouponFormData}
        />
      )}
    </div>
  );
};

export default Coupon;
