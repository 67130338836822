import React from "react";

const EditIcon = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_2040_4655)">
        <path
          d="M6.414 16.0009L16.556 5.85894L15.142 4.44494L5 14.5869V16.0009H6.414ZM7.243 18.0009H3V13.7579L14.435 2.32294C14.6225 2.13547 14.8768 2.03015 15.142 2.03015C15.4072 2.03015 15.6615 2.13547 15.849 2.32294L18.678 5.15194C18.8655 5.33947 18.9708 5.59377 18.9708 5.85894C18.9708 6.1241 18.8655 6.37841 18.678 6.56594L7.243 18.0009ZM3 20.0009H21V22.0009H3V20.0009Z"
          fill="#4D4D4D"
        />
      </g>
      <defs>
        <clipPath id="clip0_2040_4655">
          <rect
            width="24"
            height="24"
            fill="white"
            transform="translate(0 0.000976562)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default EditIcon;
