import { styled } from "@attentive-platform/stem-ui";

export const FormContainer = styled("div")(() => ({
  display: "flex",
  flexDirection: "column",
  gap: "16px",
}));

export const StyledBreakWordContainer = styled("div")(() => ({
  wordBreak: "break-word" /* Standard */,
  overflowWrap: "break-word",
  width: "100%",
}));
